import React, { useState, useCallback, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import { statCardsStyles } from "./statCards.styles";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoneyIcon from "@material-ui/icons/Money";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

import postReq from "../../utils/phpapi";

const useStyles = makeStyles(statCardsStyles);

const StatCards = () => {
  const classes = useStyles();

  const [onlineCafe, setOnlineCafe] = useState([]);
  const [onlineCafeMonth, setOnlineCafeMonth] = useState([]);

  const [onlineCafeSize, setOnlineCafeSize] = useState([]);
  const [onlineCafeName, setOnlineCafeName] = useState([]);

  const getOnlineCafe = useCallback(async (hq_id) => {
    try {
      const msg = {
        cmd: "get_today_online_product",
        hq_id: hq_id,
      };

      //return postReq("api", msg, "nahat_woo_side_post.php")
      return postReq("api", msg, "online_get.php")
        .then((response) => {
          console.log(response);
          const data = response.results;

          console.log(data);
          setOnlineCafe(data);
          let size = 0;
          data.forEach((element) => {
            //console.log(element);
            size = Number(size) + Number(element.size);
          });
          setOnlineCafeSize(size);
          const uniqueVal = [
            ...new Set(data.map((order) => order.product_name)),
          ];
          setOnlineCafeName(uniqueVal);

          // setPickups(arrayObj);

          return Promise.resolve(response);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getOnlineCafe(1);
  }, [getOnlineCafe]);

  const getOnlineCafeMonth = useCallback(async (hq_id) => {
    try {
      const msg = {
        cmd: "get_current_month_online_product",
        hq_id: hq_id,
      };

      //return postReq("api", msg, "nahat_woo_side_post.php")
      return postReq("api", msg, "online_get.php")
        .then((response) => {
          console.log(response);
          const data = response.results;

          console.log(data);
          setOnlineCafeMonth(data);

          // setPickups(arrayObj);

          return Promise.resolve(response);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getOnlineCafeMonth(1);
  }, [getOnlineCafeMonth]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.paper}>
            <div className={classes.topRow}>
              <div>
                <Typography variant="h6">Today</Typography>
                <Typography variant="h6">
                  Cafe Online {onlineCafe.length}
                </Typography>

                <Typography variant="h6">
                  Total {onlineCafeSize} Gram
                </Typography>
                <Typography variant="h6">
                  Types {onlineCafeName.length}{" "}
                </Typography>
              </div>

              <div className={classes.icon}>
                <PeopleOutlineIcon fontSize="large" />
              </div>
            </div>

            <Divider />
            <div className={classes.bottomRow}>
              <ArrowUpwardIcon className={classes.green} />
              <Typography variant="body2">
                &nbsp;
                <span className={classes.green}>{onlineCafeMonth.length}</span>
                {"  "}
                Current month
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.paper}>
            <div className={classes.topRow}>
              <div>
                <Typography variant="button">Average Bill</Typography>
                <Typography variant="h4">$7.38</Typography>
              </div>
              <div className={classes.icon}>
                <LocalAtmIcon fontSize="large" />
              </div>
            </div>
            <Divider />
            <div className={classes.bottomRow}>
              <ArrowDownwardIcon fontSize="small" className={classes.red} />
              <Typography variant="body2">
                &nbsp;<span className={classes.red}>2.3%</span> to last month
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.paper}>
            <div className={classes.topRow}>
              <div>
                <Typography variant="button">Net Profit</Typography>
                <Typography variant="h4">$2,390</Typography>
              </div>
              <div className={classes.icon}>
                <MoneyIcon fontSize="large" />
              </div>
            </div>
            <Divider />
            <div className={classes.bottomRow}>
              <ArrowUpwardIcon fontSize="small" className={classes.green} />
              <Typography variant="body2">
                &nbsp;<span className={classes.green}>2.3%</span> to last month
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.paper}>
            <div className={classes.topRow}>
              <div>
                <Typography variant="button">IG Followers</Typography>
                <Typography variant="h4">3.287</Typography>
              </div>
              <div className={classes.icon}>
                <GroupAddIcon fontSize="large" />
              </div>
            </div>
            <Divider />
            <div className={classes.bottomRow}>
              <ArrowUpwardIcon fontSize="small" className={classes.green} />
              <Typography variant="body2">
                &nbsp;<span className={classes.green}>78</span> to last month
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default StatCards;
