import axios from "axios";

export const postReq = (path, body, prefix = "index.php") => {
  const url = "https://" + "amihud.london" + "/nahat";

  // console.log("url:"+url);

  const jwt = localStorage.getItem("jwt");
  if (jwt) body.token = jwt;

  /*  axios({
    method: "post",
    url: path + "/" + prefix,
    data: {
      body,
    },
    responseType: "stream",
  })
    .then(({ data }) => data)
    .catch((err) => console.log(err), "API error info");*/

  // console.log(JSON.stringify(body), `Sending body  ${url}/${path}/${prefix}`);
  return axios
    .post(`${url}/${path}/${prefix}`, body)
    .then(({ data }) => data)
    .catch((err) => console.log(err), "API error info");
};

export default postReq;
